import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import { useClipboard } from '../hooks/useClipboard';
import './loadingDialog.scss';

const COPY_TEXT = 'Copy share link';
const OPEN_TEXT = 'Open at new tab';

function LoadingDialog({ url }) {
  const [copyText, setCopyText] = useState(COPY_TEXT);
  const copy = useClipboard();
  const base_url = window.location.origin;
  const fullUrl = `${base_url}${url}`;

  const copyHandler = (event) => {
    event.preventDefault();

    copy(fullUrl);
    setCopyText('Copied');
    setTimeout(() => setCopyText(COPY_TEXT), 1000);
  };

  return (
    <div className="loadingDialog paper">
      <div className="col">
        <div>Send this url to a friend</div>
        <div className="row">
          <a
            href={url}
            disabled={!url}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            {url ? OPEN_TEXT : 'loading'}
          </a>
          <a
            href={url}
            disabled={!url}
            onClick={copyHandler}
            className="link link-copy"
          >
            {copyText}
          </a>
        </div>
        <div className="qr-code">
          <QRCode value={fullUrl} fgColor="white" bgColor="#022626" />
        </div>
        <div className="alert">Waiting connection...</div>
      </div>
    </div>
  );
}

export default LoadingDialog;
