// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal.modalGameOver {
  color: white;
}
.modal.modalGameOver .illustration {
  margin-bottom: 25px;
  border-radius: 10px;
}
.modal.modalGameOver .illustration img {
  width: 150px;
  border-radius: 10px;
  -webkit-filter: invert(1) drop-shadow(1px 5px 5px black);
          filter: invert(1) drop-shadow(1px 5px 5px black);
}
.modal.modalGameOver.blue .body {
  background: #382491;
}
.modal.modalGameOver.red .body {
  background: #c01c1c;
}
.modal.modalGameOver.green .body {
  background: #1e7033;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/modalGameover.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,mBAAA;EACA,mBAAA;AACJ;AACI;EACE,YAAA;EACA,mBAAA;EACA,wDAAA;UAAA,gDAAA;AACN;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,mBAAA;AAFJ;AAKE;EACE,mBAAA;AAHJ","sourcesContent":[".modal.modalGameOver {\n  color: white;\n\n  .illustration {\n    margin-bottom: 25px;\n    border-radius: 10px;\n\n    img {\n      width: 150px;\n      border-radius: 10px;\n      filter: invert(1) drop-shadow(1px 5px 5px black);\n    }\n  }\n\n  &.blue .body {\n    background: #382491;\n  }\n\n  &.red .body {\n    background: #c01c1c;\n  }\n\n  &.green .body {\n    background: #1e7033;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
