import React, { useEffect, useState } from 'react';
import { useGameContext } from '../../context/gameContext';
import Modal from './modal';
import winner from '../../illustration/victory.svg';
import loser from '../../illustration/lost.svg';
import Fireworks from '../fireworks';
import './modalGameover.scss';

function ModalGameOver({ opened, onClose }) {
  const [view, setView] = useState(false);
  const { room, resetGame, myColor } = useGameContext();

  const { winnerGame } = room;

  const hasWinner = !!winnerGame;
  const isWinner = winnerGame === myColor;

  const illustration = isWinner ? winner : loser;

  useEffect(() => {
    if (opened) {
      setView(true);
    } else {
      setView(false);
    }
  }, [opened]);

  const resetGameHandler = () => {
    onClose();
    setView(false);
    setTimeout(() => resetGame(), 400);
  };

  return (
    <Modal className={`modalGameOver ${myColor}`} opened={view} zIndex={20}>
      <div className="row">
        <div className="column">
          <div className={`illustration ${myColor}`}>
            <img src={illustration} alt="" />
          </div>
          {isWinner && hasWinner && <h1>You win!</h1>}
          {isWinner && hasWinner && <Fireworks />}

          {!isWinner && hasWinner && <h1>You lose...</h1>}
          {!hasWinner && <h1>No winner</h1>}

          <div className="actions">
            <button className="dark big" onClick={resetGameHandler}>
              Play again!
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalGameOver;
