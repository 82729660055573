import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from 'react-router-dom';
// import Analytics from "react-router-ga";
import './App.scss';
import { ValueIcons } from './components/icons';
import GameProvider from './context/gameContext';
import IAContext from './context/iaContext';
import PeerProvider from './context/peerContext';
import useFontLoader from './hooks/useFontLoader';
import GameScreen from './screens/game';
import HomeScreen from './screens/home';

const fontFamilies = ['Boogaloo', 'Mohave', 'Noto Sans'];

function GameWrapper() {
  const { room } = useParams();
  const Provider = room === 'ia' ? IAContext : PeerProvider;

  return (
    <Provider>
      {({ send, receiveData }) => (
        <GameProvider send={send} receiveData={receiveData}>
          <GameScreen />
        </GameProvider>
      )}
    </Provider>
  );
}

function App() {
  const { isLoaded } = useFontLoader(fontFamilies);

  return (
    <Router>
      <div className="App">
        {!isLoaded && <div>Loading</div>}
        {isLoaded && (
          <Routes>
            <Route
              path="/rooms/:room/:color/:unlimited"
              element={<GameWrapper />}
            />
            <Route path="/rooms/:room/:color" element={<GameWrapper />} />
            <Route path="/" element={<HomeScreen />} />
          </Routes>
        )}
        <div className="bottombar">
          <a href="mailto:encarte@apessoa.com" target="_blank" rel="noreferrer">
            <ValueIcons.BUG />
            Bugs and suggestions
          </a>
          <a
            href="https://www.buymeacoffee.com/apessoa"
            target="_blank"
            rel="noreferrer"
          >
            <ValueIcons.COFFEE />
            By me a coffee!
          </a>
        </div>
      </div>
    </Router>
  );
}

export default App;
