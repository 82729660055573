import { isString } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import useUserId from '../../hooks/useUserId';
import { getOtherPlayer, randomInt } from '../../utils';
import { shuttleCards } from '../../utils/cards';
import ACTIONS, { actions } from './actions';
import useRequestAnimationFrame from '../../hooks/useRequestAnimationFrame';
import { HAND_CARDS, TURN_TIME } from '../../utils/statics';

const GameContext = React.createContext({});
const initialState = {
  userColor: '',
  room: {
    round: 0,
    turn: 0,
    turnTime: 0,
    turnTotalTime: TURN_TIME,
    green: {
      isReady: false,
      userId: null,
      name: 'Player green',
      cards: [],
      table: [],
      selected: undefined,
      position: 2,
      points: 0,
    },
    red: {
      isReady: false,
      userId: null,
      name: 'Player red',
      cards: [],
      table: [],
      selected: undefined,
      position: 5,
      points: 0,
    },
    endedRound: false,
    winnerRound: null,
    winnerGame: null,
  },
};

const reduce = (state, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }
  console.error('Action not defined', action);

  return state;
};

export const useGameContext = () => {
  const context = useContext(GameContext);
  return context;
};

function GameProvider({ children, receiveData, send }) {
  const [timer, setTimer] = useState(0);
  const userId = useUserId();
  const [state, dispatchLocal] = useReducer(reduce, initialState);

  const dispatch = useCallback(
    (action) => {
      const onlyLocalActions = [ACTIONS.SELECT_COLOR];
      if (onlyLocalActions.includes(action.type)) return false;

      dispatchLocal(action);
      send?.(action);
    },
    [send],
  );

  useEffect(() => {
    receiveData((...args) => {
      console.log('From EXTERNAL', ...args);
      dispatchLocal(...args);
    });
  }, [receiveData]);

  const canFinish =
    isString(state.room.green.selected) && isString(state.room.red.selected);
  const myColor = state.userColor
    ? state.userColor === 'green'
      ? 'green'
      : 'red'
    : null;
  const noMyColor = myColor ? (myColor === 'green' ? 'red' : 'green') : null;

  const selectColor = useCallback(
    (color) => dispatchLocal({ type: ACTIONS.SELECT_COLOR, color, userId }),
    [dispatchLocal, userId],
  );

  const setName = useCallback(
    (color, name) =>
      dispatch({
        type: ACTIONS.SET_NAME,
        color,
        name: name?.trim() || 'Anonymous',
      }),
    [dispatch],
  );

  const drawCards = useCallback(
    (color = myColor) =>
      dispatch({
        type: ACTIONS.DRAW_CARDS,
        color,
        cards: shuttleCards(),
      }),
    [dispatch, myColor],
  );

  const completeTurn = useCallback(() => {
    if (canFinish) {
      dispatch({
        type: ACTIONS.COMPLETE_TURN,
        newTime: new Date().getTime() + 100,
        turn: state.room.turn + 1,
      });
    }
  }, [canFinish, dispatch, state.room.turn]);

  const selectCard = useCallback(
    (player, cardKey) => {
      if (!state.room.endedRound) {
        dispatch({ type: ACTIONS.SELECT_CARD, player, cardKey });

        const otherPlayer = getOtherPlayer(player);

        if (state.room[otherPlayer].selected !== undefined) {
          setTimeout(
            () =>
              dispatch({
                type: ACTIONS.COMPLETE_TURN,
                newTime: Date.now(),
                turn: state.room.turn + 1,
              }),
            500,
          );
        }
      }
    },
    [dispatch, state],
  );

  const setTurnMax = useCallback(
    (value) => dispatch({ type: ACTIONS.SET_TURN_TIME, value }),
    [dispatch],
  );

  const resetRound = useCallback(() => {
    if (state.room.endedRound || state.room.round === 0) {
      dispatch({
        type: ACTIONS.RESET_ROUND,
        newTime: new Date().getTime(),
        round: state.room.round + 1,
      });
    }
  }, [dispatch, state.room.endedRound, state.room.round]);

  const getAvailableColor = useCallback(() => {
    if (state.room.green.userId === null) return 'green';
    if (state.room.red.userId === null) return 'red';
    return undefined;
  }, [state]);

  const { play, stop } = useRequestAnimationFrame(
    useCallback(
      (stop, time) => {
        if (!state.room.turnTotalTime) return;

        const progress = Math.min(
          (time - state.room.turnTime) / state.room.turnTotalTime,
          1,
        );

        setTimer(progress);
        if (progress >= 1) {
          stop();

          setTimeout(() => {
            dispatch({
              type: ACTIONS.COMPLETE_TURN,
              timeOut: randomInt(0, HAND_CARDS - 1),
              newTime: Date.now(),
              turn: state.room.turn + 1,
            });
          }, 1600);
        }
      },
      [
        state.room.turnTime,
        state.room.turnTotalTime,
        state.room.turn,
        dispatch,
      ],
    ),
  );

  const resetGame = useCallback(() => {
    stop();
    dispatch({
      type: ACTIONS.RESET_GAME,
      newTime: Date.now(),
    });
  }, [dispatch, stop]);

  const setReady = useCallback(
    (color) => {
      dispatch({ type: ACTIONS.SET_READY, color, status: true });
    },
    [dispatch],
  );

  useEffect(() => {
    const isNewTurn =
      !canFinish &&
      !state.room.endedRound &&
      state.room.round > 0 &&
      state.room.winnerGame === null;

    if (isNewTurn) {
      play();
    } else {
      stop();
    }
  }, [
    canFinish,
    state.room.winnerGame,
    state.room.endedRound,
    state.room.turnTime,
    state.room.round,
    dispatch,
    play,
    stop,
  ]);

  useEffect(() => {
    if (state.room.green.isReady && state.room.red.isReady) {
      if (state.room.turn === 0) {
        drawCards();
        if (state.room.round === 0) {
          resetRound();
        }
        setTimeout(play, 250);
      } else {
        resetRound();
      }
    }
  }, [
    state.room.green.isReady,
    state.room.red.isReady,
    state.room.turn,
    play,
    resetRound,
  ]);

  return (
    <GameContext.Provider
      value={{
        ...state,
        play,
        stop,
        timer,
        myColor,
        noMyColor,
        me: state.room[myColor],
        opponent: state.room[noMyColor],
        canFinish,
        selectCard,
        drawCards,
        setReady,
        resetGame,
        completeTurn,
        resetRound,
        selectColor,
        setName,
        setTurnMax,
        getAvailableColor,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export default GameProvider;
