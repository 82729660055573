import React from 'react';
import { useGameContext } from '../../context/gameContext';
import Cards from '../cards';
import ModalResult from '../modals/modalResult';
import Rail from '../rail';
import Timer from './timer';
import './index.scss';
import Title from '../title';
import { TURN_TIME } from '../../utils/statics';
import HitLight from './hitLight';

function MiniCard({ color }) {
  return (
    <span
      className="mini-card"
      style={{
        background: color,
      }}
    />
  );
}

function Table() {
  const { room, opponent, me, myColor, noMyColor, timer } = useGameContext();
  const { round, turn, winnerRound, endedRound, winnerGame } = room;
  const shouldShowTimer = !!room.turnTotalTime;

  return (
    <>
      <Cards player={noMyColor} />
      <div className="header">
        <Title />
      </div>
      <div>
        <div className="score">
          <pre style={{ display: 'none' }}>
            Round: {round}/{turn} -
          </pre>
          <HitLight color={myColor} />
          <MiniCard color={myColor} />
          <span>{me.points}</span>
          <span> x </span>
          <span>{opponent.points}</span>
          <MiniCard color={noMyColor} />
          <HitLight color={noMyColor} />
        </div>
        <div className="middle">
          <div className="cards cards-player">
            <ul className="cards-table">
              <li
                className={`card-slot ${myColor}`}
                id={`${myColor}-0`}
                key={`${myColor}-0`}
              />
              <li
                className={`card-slot ${myColor}`}
                id={`${myColor}-1`}
                key={`${myColor}-1`}
              />
            </ul>
          </div>
          <div className="rail-container">
            <Rail />
            {winnerRound && <strong>{winnerRound} pointed</strong>}
            {!winnerRound && endedRound && <strong>No winner</strong>}
            {winnerGame && <strong>{winnerGame} is the winner!</strong>}
            {!winnerGame && !endedRound && shouldShowTimer && (
              <Timer timer={timer} totalTime={room.turnTotalTime} />
            )}
            <ModalResult opened={endedRound} />
          </div>
          <div className="cards cards-opponent">
            <ul className="cards-table">
              <li
                className={`card-slot ${noMyColor}`}
                id={`${noMyColor}-0`}
                key={`${noMyColor}-0`}
              />
              <li
                className={`card-slot ${noMyColor}`}
                id={`${noMyColor}-1`}
                key={`${noMyColor}-1`}
              />
            </ul>
          </div>
        </div>
        <Cards player={myColor} renderHand />
      </div>
    </>
  );
}

export default Table;
