// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 0;
}
.cards > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.cards.cards-player .cards-table {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.cards ul {
  padding: 0;
  margin: 0;
}
.cards ul.cards-hand, .cards ul.cards-table {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.cards ul.cards-table .card-slot {
  border: 0.5em solid rgba(255, 255, 255, 0.25);
  font-size: 8.5px;
}
.cards ul.cards-table .card-slot.blue {
  border-color: rgba(113, 111, 199, 0.2509803922);
}
.cards ul.cards-table .card-slot.red {
  border-color: rgba(199, 111, 111, 0.2509803922);
}
.cards ul.cards-table .card-slot.green {
  border-color: rgba(120, 194, 138, 0.2509803922);
}
.cards ul .card-slot {
  font-size: 10px;
  min-width: 15.75em;
  max-width: 15.75em;
  min-height: 22em;
  max-height: 22em;
  border-radius: 1em;
  margin: 0 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/cards.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,6BAAA;EACA,UAAA;AACF;AACE;EACE,oBAAA;EAAA,oBAAA;EAAA,aAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,WAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;AACJ;AAEE;EACE,8BAAA;EAAA,8BAAA;MAAA,+BAAA;UAAA,2BAAA;AAAJ;AAGE;EACE,UAAA;EACA,SAAA;AADJ;AAGI;EAEE,2BAAA;EAAA,2BAAA;EAAA,oBAAA;AAFN;AAMM;EACE,6CAAA;EACA,gBAAA;AAJR;AAMQ;EACE,+CAAA;AAJV;AAOQ;EACE,+CAAA;AALV;AAQQ;EACE,+CAAA;AANV;AAWI;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EAEA,eAAA;AAVN","sourcesContent":[".cards {\n  display: flex;\n  justify-content: space-around;\n  padding: 0;\n\n  & > span {\n    display: flex;\n    align-items: center;\n    width: 30px;\n    justify-content: center;\n  }\n\n  &.cards-player .cards-table {\n    flex-direction: row-reverse;\n  }\n\n  ul {\n    padding: 0;\n    margin: 0;\n\n    &.cards-hand,\n    &.cards-table {\n      display: inline-flex;\n    }\n\n    &.cards-table {\n      .card-slot {\n        border: 0.5em solid rgba(255, 255, 255, 0.25);\n        font-size: 8.5px;\n\n        &.blue {\n          border-color: #716fc740;\n        }\n\n        &.red {\n          border-color: #c76f6f40;\n        }\n\n        &.green {\n          border-color: #78c28a40;\n        }\n      }\n    }\n\n    .card-slot {\n      font-size: 10px;\n      min-width: 15.75em;\n      max-width: 15.75em;\n      min-height: 22em;\n      max-height: 22em;\n      border-radius: 1em;\n\n      margin: 0 0.5em;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
