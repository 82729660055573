import { useCallback, useLayoutEffect, useRef } from 'react';

export default function useCardEffect({ active } = {}) {
  const cardRef = useRef(null);
  const card = cardRef.current;
  const cardTransitionRef = useRef();

  const cardEffect = useCallback((event) => {
    const card = cardRef.current;
    if (card) {
      const cardData = cardRef.current.getBoundingClientRect();

      const propX = (event.pageX - cardData.x) / cardData.width;
      const propY = (event.pageY - cardData.y) / cardData.height;

      const flipX = 0.5 - propX;
      const flipY = 0.5 - propY;
      const perspective = 20;

      const rotateX = (+1 * perspective * flipY).toFixed(2);
      const rotateY = (-1 * perspective * flipX).toFixed(2);

      card.style.transform = `perspective(500px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    }
  }, []);

  const cardTransition = useCallback((milleseconds = 400) => {
    const card = cardRef.current;
    if (card) {
      if (cardTransitionRef.current) clearTimeout(cardTransitionRef.current);
      card.style.transition = `transform ${milleseconds}ms`;
      cardTransitionRef.current = setTimeout(() => {
        card.style.transition = '';
      }, milleseconds);
    }
  }, []);

  const cardReset = useCallback(
    (event) => {
      const card = cardRef.current;
      if (card) {
        card.style.transform = 'perspective(500px) rotateX(0deg) rotateY(0deg)';
        cardTransition();
      }
    },
    [cardTransition],
  );

  const cardEnter = useCallback(() => {
    cardTransition();
  }, [cardTransition]);

  useLayoutEffect(() => {
    if (active && card) {
      card.addEventListener('mousemove', cardEffect);
      card.addEventListener('mouseleave', cardReset);
      card.addEventListener('mouseenter', cardEnter);
    } else if (card) {
      card.style.transition = '';
    }

    return () => {
      card?.removeEventListener('mousemove', cardEffect);
      card?.removeEventListener('mouseleave', cardReset);
      card?.removeEventListener('mouseenter', cardEnter);
    };
  }, [active, cardEffect, cardReset, cardEnter, card]);

  return cardRef;
}
