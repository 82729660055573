import Cards, { CardType } from '../../utils/cards';

export const isValidAttack = (cardKey, distance) => Cards[cardKey].type === CardType.ATTACK && distance < 2;

export const calculateTimeoutCardMovement = (initPositionA, initPositionB) => {
  const dist = Math.abs(initPositionB - initPositionA);

  if (dist > 2) return 1;
  if (dist < 2) return -1;
  return 0;
};

export const resolveConflict = ({
  red: r,
  redCard,
  redPreviousCard,
  green: g,
  greenCard,
  greenPreviousCard,
}) => {
  let endedRound = false;
  let winnerRound = null;

  // sum previous card and current one power
  const green = {
    ...g,
    attack: (greenCard?.attack || 0) + (greenPreviousCard?.attackBonus || 0),
    defense: (greenCard?.defense || 0) + (greenPreviousCard?.defenseBonus || 0),
    position: g.position + (greenCard?.movement || 0),
    movement: 0,
    power: 0,
  };

  const red = {
    ...r,
    attack: (redCard?.attack || 0) + (redPreviousCard?.attackBonus || 0),
    defense: (redCard?.defense || 0) + (redPreviousCard?.defenseBonus || 0),
    position: r.position - (redCard?.movement || 0),
    movement: 0,
    power: 0,
  };

  const dist = red.position - green.position;

  // calculate cross movement pennalties
  if (dist < 1) {
    // cross movement penalty
    endedRound = true;
    if (greenCard.movement !== 0) {
      green.power -= greenCard.movement + 1;
      green.movement = greenCard.movement;
    }
    if (redCard.movement !== 0) {
      red.power -= redCard.movement + 1;
      red.movement = redCard.movement;
    }
  }

  // verify it there is any touch
  if (isValidAttack(greenCard.key, dist) && isValidAttack(redCard.key, dist)) {
    green.power += green.attack - red.defense;
    red.power += red.attack - green.defense;
  } else if (isValidAttack(greenCard.key, dist)) {
    green.power += Math.max(0, green.attack - red.defense);
  } else if (isValidAttack(redCard.key, dist)) {
    red.power += Math.max(0, red.attack - green.defense);
  } else {
    // resolve "timeout cards" "get closer" movement
    if (
      (redCard.type === CardType.TIMEOUT
        || greenCard.type === CardType.TIMEOUT)
      && dist > 0
    ) {
      // if both is waiting, go to center
      if (
        redCard.type === CardType.TIMEOUT
        && greenCard.type === CardType.TIMEOUT
      ) {
        const middleRail = 7 / 2;
        const gDelta = Math.abs(green.position - middleRail);
        const rDelta = Math.abs(red.position - middleRail);

        if (dist > 2) {
          if (gDelta < rDelta || dist > 3) {
            red.position -= 1;
          }
          if (gDelta > rDelta || dist > 3) {
            green.position += 1;
          }
        }
      } else if (redCard.type === CardType.TIMEOUT) {
        red.position -= calculateTimeoutCardMovement(
          green.position,
          red.position,
        );
      } else {
        // if (greenCard.type === CardType.TIMEOUT)
        green.position += calculateTimeoutCardMovement(
          green.position,
          red.position,
        );
      }
      // never auto-go out trail
      green.position = Math.max(green.position, 0);
      red.position = Math.min(red.position, 7);
    }
  }

  // verify if any player moved out of the rail
  const isRedRailOut = red.position < 0 || red.position > 7;
  const isGreenRailOut = green.position < 0 || green.position > 7;

  // calculate turn final result
  if (isRedRailOut || isGreenRailOut) {
    endedRound = true;

    if (isGreenRailOut && isRedRailOut) {
      winnerRound = 'none';
    } else if (isGreenRailOut) {
      winnerRound = 'red';
      red.points += 1;
    } else {
      winnerRound = 'green';
      green.points += 1;
    }
  } else if (green.power || red.power) {
    endedRound = true;

    if (green.power > red.power) {
      winnerRound = 'green';
      green.points += 1;
    } else if (red.power > green.power) {
      winnerRound = 'red';
      red.points += 1;
    } else {
      winnerRound = 'none';
    }
  }

  return {
    red,
    green,
    endedRound,
    winnerRound,
  };
};
