import { v4 as uuidv4 } from 'uuid';
import { shuffle } from './index';

export const CardType = {
  ATTACK: 'attack',
  DEFENSE: 'defense',
  TIMEOUT: 'timeout',
};

export const CardsList = [
  {
    key: 'move',
    type: CardType.ATTACK,
    label: 'Advance & attack',
    movement: 1,
    attack: 1,
    defense: 1,
    attackBonus: 1,
    defenseBonus: 0,
    count: 10,
  },
  {
    key: 'moveback',
    type: CardType.DEFENSE,
    label: 'Retreat',
    movement: -1,
    attack: 1,
    defense: 2,
    attackBonus: 1,
    defenseBonus: 2,
    count: 5,
  },
  {
    key: 'doublemove',
    type: CardType.ATTACK,
    label: 'Deep advance & attack',
    movement: 2,
    attack: 1,
    defense: 0,
    attackBonus: 2,
    defenseBonus: -2,
    count: 2,
  },
  {
    key: 'doublemoveback',
    type: CardType.DEFENSE,
    label: 'Romper',
    movement: -2,
    attack: 0,
    defense: 2,
    attackBonus: 0,
    defenseBonus: 1,
    count: 2,
  },
  {
    key: 'fullattack',
    type: CardType.ATTACK,
    label: 'Lunge',
    movement: 1,
    attack: 4,
    defense: 2,
    attackBonus: -1,
    defenseBonus: -1,
    count: 3,
  },
  {
    key: 'attack',
    type: CardType.ATTACK,
    label: 'Attack',
    movement: 0,
    attack: 3,
    defense: 1,
    attackBonus: 0,
    defenseBonus: 0,
    count: 5,
  },
  {
    key: 'defense',
    type: CardType.DEFENSE,
    label: 'Parry',
    movement: 0,
    attack: 0,
    defense: 4,
    attackBonus: 1,
    defenseBonus: 1,
    count: 5,
  },
  {
    key: 'feint',
    type: CardType.DEFENSE,
    label: 'Feint',
    movement: 0,
    attack: 0,
    defense: 3,
    attackBonus: 3,
    defenseBonus: 0,
    count: 2,
  },
  {
    key: 'counterattack',
    type: CardType.ATTACK,
    label: 'Riposte',
    movement: 0,
    attack: 2,
    defense: 3,
    attackBonus: 1,
    defenseBonus: 2,
    count: 2,
  },
  {
    key: 'timeout',
    type: CardType.TIMEOUT,
    label: 'Keep distance',
    movement: 0,
    attack: 0,
    defense: 0,
    attackBonus: -1,
    defenseBonus: -1,
    count: 0,
  },
];

const Cards = Object.fromEntries(CardsList.map((card) => [card.key, card]));

export default Cards;

export const shuttleCards = () => {
  const keys = Object.keys(Cards);
  let cards = keys.reduce((acc, key) => {
    const { count } = Cards[key];
    for (let index = 0; index < count; index += 1) {
      const id = uuidv4().replaceAll(/-/gi, '');
      acc.push({ id, key });
    }
    return acc;
  }, []);

  cards = shuffle(cards);

  return cards;
};

let cardsCountCache = 0;
export const cardsCount = () => {
  if (cardsCountCache) return cardsCountCache;

  const keys = Object.keys(Cards);
  keys.forEach((key) => {
    const { count } = Cards[key];

    cardsCountCache += count;
  });

  return cardsCountCache;
};

export const isMovement = (type) => Cards[type].movement !== 0;
