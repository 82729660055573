import React from 'react';
import { useGameContext } from '../context/gameContext';
import { CardIcons } from './icons';
import './rail.scss';

const rail = [0, 1, 2, 3, 4, 5, 6, 7];

function Rail() {
  const {
    me, opponent, myColor, noMyColor,
  } = useGameContext();

  const myCardKey = me?.table?.[0]?.key;
  const opponentCardKey = opponent?.table?.[0]?.key;

  const myIcon = (myCardKey !== 'timeout' && CardIcons[myCardKey]) || CardIcons.engard;
  const opponentIcon = (opponentCardKey !== 'timeout' && CardIcons[opponentCardKey])
    || CardIcons.engard;

  const myPosition = myColor === 'red' ? rail.length - 1 - me.position : me.position;
  const noMyPosition = myColor === 'red' ? rail.length - 1 - opponent.position : opponent.position;

  return (
    <div className="rail">
      <ul className="rail-content">
        {rail.map((pos, index) => (
          <li
            key={index}
            className={[
              'slot',
              myPosition === pos ? `slot-${myColor}` : '',
              noMyPosition === pos ? `slot-${noMyColor}` : '',
            ].join(' ')}
          />
        ))}
        <li
          className={`player-left player-${myColor}`}
          style={{ left: `${(100 / rail.length) * myPosition}%` }}
        >
          <span>
            <img src={myIcon} alt="my icon" />
          </span>
        </li>
        <li
          className={`player-right player-${noMyColor}`}
          style={{ left: `${(100 / rail.length) * noMyPosition}%` }}
        >
          <span>
            <img src={opponentIcon} alt="opponent icon" />
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Rail;
