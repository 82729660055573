import React, { useMemo } from 'react';
import { useGameContext } from '../context/gameContext';
import { useCards, useRoom, usePlayer } from '../context/gameContext/hooks';
import { HAND_CARDS, TURN_TIME } from '../utils/statics';
import Card from './card';
import './cards.scss';

function Slots({ count = 6, playerKey }) {
  const slots = useMemo(
    () =>
      [...Array(count)].map((_, index) => (
        <li
          className={`card-slot ${playerKey}`}
          id={`${playerKey}-${index + 2}`}
          key={`${playerKey}-${index + 2}`}
        />
      )),
    [count, playerKey],
  );

  return <ul className="cards-hand">{slots}</ul>;
}

const CardsList = React.memo(
  ({ list, locked: forceLocked, playerKey, selectedId, selectCard }) =>
    list.map(({ id, key, last, locked, timeout, bonus, stack, slot }) => (
      <div key={`${id}-${key}-${playerKey}`}>
        <Card
          id={`${id}-${key}-${playerKey}`}
          type={key}
          color={playerKey}
          bonus={bonus}
          locked={forceLocked || locked || last}
          selected={
            selectedId === id || (selectedId === 0 && key === 'timeout')
          }
          hidden={last}
          stack={!!stack?.length}
          slot={slot}
          onClick={() => selectCard(playerKey, !timeout ? id : 0)}
        />
      </div>
    )),
);

function Cards({ player: playerKey, renderHand = false }) {
  const { selectCard } = useGameContext();
  const { endedRound, canFinish, turnTotalTime } = useRoom();
  const { selected } = usePlayer(playerKey);
  const cards = useCards({ renderHand, playerKey });

  const isTimeLimited = !!turnTotalTime;

  return (
    <div className="cards">
      <CardsList
        list={cards}
        locked={endedRound || canFinish}
        playerKey={playerKey}
        selectedId={selected}
        selectCard={selectCard}
      />
      {renderHand && (
        <Slots count={isTimeLimited ? 6 : 7} playerKey={playerKey} />
      )}
    </div>
  );
}

export default Cards;
