import { isString } from 'lodash';
import { isDataAndStateEquals, randomInt } from '../../utils';
import Cards from '../../utils/cards';
import { GAME_MAX_POINTS, HAND_CARDS } from '../../utils/statics';
import { resolveConflict } from './logics';

export const actions = {
  chooseCard: (state, action) => {
    const { player: targetKey, timeOut } = action;
    const target = state.room[targetKey];
    const cards = [...target.cards];

    const hasSel = isString(target.selected);

    const discartCardIndex = !hasSel
      ? Math.min(timeOut, cards.length - 1, HAND_CARDS)
      : cards.findIndex((t) => t.id === target.selected);
    const selCard = cards.splice(discartCardIndex, 1)[0];

    const handCard = !hasSel
      ? { id: randomInt(0, 1000), key: Cards.timeout.type, stack: [selCard] }
      : selCard;

    if (!handCard) {
      console.error('Invalid card');

      return state;
    }

    const tTable = target.table || [];
    const table = [handCard, ...tTable];

    return {
      ...state,
      room: {
        ...state.room,
        [targetKey]: {
          ...target,
          cards,
          table,
          selected: undefined,
        },
      },
    };
  },

  drawCards: (state, action) => {
    const { color, cards: newCards } = action;

    return {
      ...state,
      room: {
        ...state.room,
        [color]: {
          ...state.room[color],
          cards: newCards,
        },
      },
    };
  },

  selectCard: (state, action) => {
    const { player: playerKey, cardKey } = action;
    const target = state.room[playerKey];
    const isSame = target.selected === cardKey;

    return {
      ...state,
      room: {
        ...state.room,
        [playerKey]: {
          ...target,
          selected: !isSame ? cardKey : undefined,
        },
      },
    };
  },

  completeTurn: (state, action) => {
    const { timeOut, newTime, turn } = action;

    // check if it is just a resync

    if (turn <= state.room.turn) {
      if (newTime < state.room.turnTime) {
        return state;
      }
      return {
        ...state,
        room: {
          ...state.room,
          turnTime: newTime,
        },
      };
    }

    const isTimeout = timeOut !== undefined;

    // if this end turn is not valid ignore it

    if (
      (!isTimeout &&
        (state.room.red.selected === undefined ||
          state.room.green.selected === undefined)) ||
      state.room.winnerGame !== null
    ) {
      return state;
    }

    // move selected card to table

    state = actions.chooseCard(state, {
      type: ACTIONS.CHOOSE_CARD,
      player: 'green',
      timeOut,
    });
    state = actions.chooseCard(state, {
      type: ACTIONS.CHOOSE_CARD,
      player: 'red',
      timeOut,
    });

    // calculate cards effects

    const { green: g, red: r } = state.room;
    //
    const greenCardKey = g.table[0].key;
    const greenCard = Cards[greenCardKey];
    const greenPreviousCard = Cards[g.table[1]?.key];
    //
    const redCardKey = r.table[0].key;
    const redCard = Cards[redCardKey];
    const redPreviousCard = Cards[r.table[1]?.key];

    const { red, green, endedRound, winnerRound } = resolveConflict({
      green: g,
      greenCard,
      greenPreviousCard,
      red: r,
      redCard,
      redPreviousCard,
    });

    if (endedRound) {
      red.isReady = false;
      green.isReady = false;
    }

    // verify game result
    let winnerGame = null;

    if (green.points >= GAME_MAX_POINTS) {
      winnerGame = 'green';
    } else if (red.points >= GAME_MAX_POINTS) {
      winnerGame = 'red';
    } else if (!green.cards.length || !red.cards.length) {
      winnerGame = 'none';
    }

    return {
      ...state,
      room: {
        ...state.room,
        turnTime: !winnerGame && newTime,
        turn,
        green,
        red,
        endedRound,
        winnerRound,
        winnerGame,
      },
    };
  },

  resetRound: (state, action) => {
    const { newTime, round } = action;

    // check if it is just a resync
    if (round <= state.room.round) {
      if (newTime < state.room.turnTime) {
        return state;
      }
      return {
        ...state,
        room: {
          ...state.room,
          turnTime: newTime,
        },
      };
    }

    return {
      ...state,
      room: {
        ...state.room,
        turnTime: newTime,
        round,
        endedRound: false,
        winnerRound: null,
        green: {
          ...state.room.green,
          table: [],
          position: 2,
          attack: 0,
          defense: 0,
          selected: undefined,
        },
        red: {
          ...state.room.red,
          table: [],
          position: 5,
          attack: 0,
          defense: 0,
          selected: undefined,
        },
      },
    };
  },

  resetGame: (state, action) => {
    const { newTime } = action;

    return {
      ...state,
      room: {
        ...state.room,
        turnTime: newTime,
        turn: 0,
        round: 0,
        endedRound: false,
        winnerRound: null,
        winnerGame: null,
        green: {
          ...state.room.green,
          isReady: false,
          table: [],
          points: 0,
          position: 2,
          attack: 0,
          defense: 0,
          selected: undefined,
        },
        red: {
          ...state.room.red,
          isReady: false,
          table: [],
          points: 0,
          position: 5,
          attack: 0,
          defense: 0,
          selected: undefined,
        },
      },
    };
  },

  dataLoaded: (state, action) => {
    const { ...data } = action.data || {};
    const { room } = state;
    const hasUpdate = !isDataAndStateEquals(data, room);
    const newRoom = { ...room, ...data };

    return hasUpdate ? { ...state, room: newRoom } : state;
  },

  selectColor: (state, action) => {
    const { color, userId } = action;
    const roomColor = state.room[color];

    return {
      ...state,
      userColor: color,
      room: {
        ...state.room,
        [color]: {
          ...roomColor,
          userId,
        },
      },
    };
  },

  setName: (state, action) => {
    const { color, name } = action;
    const roomColor = state.room[color];

    return {
      ...state,
      room: {
        ...state.room,
        [color]: {
          ...roomColor,
          name,
        },
      },
    };
  },

  setTurnTime: (state, action) => {
    const { value } = action;

    return {
      ...state,
      room: {
        ...state.room,
        turnTotalTime: value,
      },
    };
  },

  setReady: (state, action) => {
    const { color, status } = action;
    const roomColor = state.room[color];

    return {
      ...state,
      room: {
        ...state.room,
        [color]: {
          ...roomColor,
          isReady: status,
        },
      },
    };
  },
};

const ACTIONS = Object.keys(actions).reduce((acc, curr) => {
  const key = curr.replace(/([A-Z])/g, '_$1').toUpperCase();

  return { [key]: curr, ...acc };
}, {});

export default ACTIONS;
