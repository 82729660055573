import React from 'react';
import { useGameContext } from '../../context/gameContext';
import Modal from './modal';

function ModalStart({ opened, onClose }) {
  const {
    room, myColor, noMyColor, setReady,
  } = useGameContext();

  const setReadyHandler = () => {
    setReady(myColor);
  };

  const areYouReady = room[myColor]?.isReady;
  const areOpponentReady = room[noMyColor]?.isReady;

  return (
    <Modal className="modalStart" opened={opened} zIndex={20}>
      <div className="row">
        <div className="column">
          <h1>Start game!</h1>
          {!areYouReady && <p>Are you ready?</p>}
          {areYouReady && !areOpponentReady && <p>Waiting opponent...</p>}
          {areYouReady && areOpponentReady && <p>Starting game!</p>}
        </div>
      </div>
      <div className="row">
        <div className="column">
          <div className="actions">
            <button
              className="dark big"
              onClick={setReadyHandler}
              disabled={areYouReady}
            >
              Start
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalStart;
